.Overlay .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0) !important;
    transition: background-color 0.4s ease-in-out;
    z-index: 999;
}

.Overlay .ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5) !important;
}
.Overlay .ReactModal__Overlay--before-close {
    background-color: rgba(0, 0, 0, 0) !important;
}